import '../../scss/home.scss';
import '../vendors/cssrelpreload.js';
import '../vendors/bootstrap.min.js';
import '../vendors/lazy-load-img.js';
import '../vendors/google-font.js';
import '../vendors/jquery.validate.min.js';
import '../custom/parallax.js';
import '../custom/header-fade.js';
import '../custom/widget.js';
import '../custom/hire-console.js';
import '../custom/recruiter-form.js';